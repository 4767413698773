@-webkit-keyframes blink{
	0% {
		border-color:red;
		opacity:0;
	}
	50% {
		border-color: #57980b;
		opacity:1;
	}
	100% {
		border-color:red;
		opacity:0;
	}
}
@keyframes blink{
	0% {
		border-color:red;
		opacity:0;
	}
	50% {
		border-color: #57980b;
		opacity:1;
	}
	100% {
		border-color:red;
		opacity:0;
	}
}

@-webkit-keyframes new_blink{
	0% {
		background-color: #fff
	}
	50% {
		background-color: rgba(251,250,225,0.94);
	}
}
@keyframes new_blink{
	0% {
		background-color: #fff
	}
	50% {
		background-color: rgba(251,250,225,0.94);
	}
}


@-webkit-keyframes header_blink{
	0% {
		background-color: #295d9f;
	}
	50% {
		background-color: #03a9f4;
	}
}
@keyframes header_blink{
	0% {
		background-color: #295d9f;
	}
	50% {
		background-color: #03a9f4;
	}
}

@keyframes ripple {
  0% {
    //box-shadow: 0 0.5em 0.5em rgba(0,0,0, 0.4);
    transform: scale(1);
  }

  50% {
    //box-shadow: 0 1.5em 1.5em rgba(0,0,0, 0.4);
    transform: scale(1.4);
  }
}






@keyframes UPfadein{
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes UPfadein{ /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes UPfadein{ /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes UPfadein{ /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

@keyframes UPfadeOut{
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
@-moz-keyframes UPfadeOut{ /* Firefox */
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
@-webkit-keyframes UPfadeOut{ /* Safari and Chrome */
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
@-o-keyframes UPfadeOut{ /* Opera */
    from {
        opacity:1;
    }
    to {
        opacity: 0;
    }
}

.up-fadeIn{
	-moz-animation: UPfadein 0.5s; /* Firefox */
    -webkit-animation: UPfadein 0.5s; /* Safari and Chrome */
    -o-animation: UPfadein 0.5s; /* Opera */
}

.up-fadeOut{
	-moz-animation: UPfadeOut 2s; /* Firefox */
    -webkit-animation: UPfadeOut 2s; /* Safari and Chrome */
    -o-animation: UPfadeOut 2s; /* Opera */
	display: none !important;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-10px);
  }

  40% {
    transform: translateX(10px);
  }

  60% {
    transform: translateX(-10px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes play{
	0% {
		transform: scale(1);
	}
	15% {
		box-shadow: 0 0 0 10px #354656;
	}
	25% {
		box-shadow: 0 0 0 10px #354656, 0 0 0 20px #293947;
	}
	30% {
		transform: scale(1.2);
	}
	50% {
		box-shadow: 0 0 0 10px #354656, 0 0 0 20px #293947, 0 0 0 30px #243240, 0 0 0 40px #19222b;
	}
	80% {
		transform: scale(1);
	}
}


.ripple-anim{
	-webkit-animation: ripple 3s infinite ease-in-out;
	-animation: ripple 3s infinite ease-in-out;
	-moz-animation: ripple 3s infinite ease-in-out;
}


.shake{
	animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}
.shake-infinite{
	animation: shake 0.82s infinite cubic-bezier(.36,.07,.19,.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

.h-load{
	-webkit-animation: h-load 30s 1 linear;
	-animation: h-load 30s	1 linear;
	-moz-animation: h-load 30s 1 linear;
	&.default{
		background: $theme-color-1;
		display: inline-block;
		padding:1px;
		box-sizing:border-box;
	}
}

@keyframes placeHolderShimmer{
    0%{
        background-position-x: -100%;
    }
    100%{
        background-position-x: 100%;
    }
}

@keyframes zoomIn {
  from {
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
  }
  to{
	  -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1,1, 1);
  }
}

.zoomIn {
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn;
}
.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: cubic-bezier;
    background: linear-gradient(to right, #fff 0%, #F2F2F2 25%, #fff 100%);
	border-radius: 2px;
    background-size: 50%;
    height: 55px;
    position: relative;
}
// animation classes end

.blink-highlight{
	-webkit-animation: blink 1s infinite ease-in-out;
	-animation: blink 1s infinite ease-in-out;
	-moz-animation: blink 1s infinite ease-in-out;
}

.new-blink{
	background-color: rgba(251,250,225,0.94);
	-webkit-animation: new_blink 1s 3 ease-in-out;
	-animation: new_blink 1s 3 ease-in-out;
	-moz-animation: new_blink 1s 3 ease-in-out;
}

.header-blink{
	-webkit-animation: header_blink 500ms infinite ease-in-out;
	-animation: header_blink 500ms infinite ease-in-out;
	-moz-animation: header_blink 500ms infinite ease-in-out;
}


.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}


@keyframes OptionSlideIn{
    from {
        left:100%;
    }
    to {
        left:33%;
    }
}


.nested-options-slide{
	-webkit-animation: OptionSlideIn 0.7s;
	-animation: OptionSlideIn 0.7s;
	-moz-animation: OptionSlideIn 0.7s;
}

.rotate-infinite{
		-webkit-animation-name: spin;
	    -webkit-animation-duration: 500ms;
	    -webkit-animation-iteration-count: infinite;
	    -webkit-animation-timing-function: linear;

	    animation-name: spin;
	    animation-duration: 500ms;
	    animation-iteration-count: infinite;
	    animation-timing-function: linear;
}


@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes hloadAnim {
	0%{
		width:0px;
	}
	50%{
		width: 50%;
	}
	100%{
		width: 1000%;
	}
}

.hload-anim{
	animation: hloadAnim 1s infinite ease-in-out;
    height: 2px;
    background: #34485d;
    width: 10px;
    position: absolute;
    bottom: -3px;
    transform-origin: left;
    left: 0;
    transition: 180ms;
}
