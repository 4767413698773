$padding: 20px 30px;
$shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

.SeamlessPrintInstructions {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 10000;
	overflow-x: hidden;

	width: 430px;
	box-shadow: $shadow;

	background-color: white;
	border-radius: 3px;
	transform: translate(-50%, -50%);

	* {
		box-sizing: border-box;
	}

	.header {
		color: #111;
		font-size: 19px;
		font-weight: 500;
		padding: 0px;
	}

	p {
		margin-bottom: 17px;
		font-size: 14px;
	}

	b {
		font-weight: 500;
	}

	a {
		color: #2d5cad;
		text-decoration: underline;
	}

	.button-container {
		margin-top: 40px;
		display: flex;
		justify-content: center;
		width: 100%;
		a.up-button {
			// margin: 10px 20px 10px 0;
			padding: 10px 20px;
			width: 140px !important;
			text-align: center;
			text-decoration: none;

			&.dismiss {
				margin-right: 10px;
				color: #e74c3c !important;
				border: 1px solid darken(#e74c3c, 10%) !important;
				background: #fff !important;
			}
		}
	}

}

.SeamlessPrintInstructions-Slides {
	display: flex;
	width: 100%;
	transition: transform 0.3s ease 0s;

	&.first-slide {
		transform: translateX(0);
	}

	&.second-slide {
		transform: translateX(-100%);
	}

	> * {
		flex-shrink: 0;
		width: 100%;
		padding: $padding;
	}
}

.SeamlessPrintInstructions-Steps {
	margin: 25px 0;
}


.SeamlessPrintInstructions-TestPrintContainer {
	margin-top: 20px;
}

.SeamlessPrintInstructions-TestPrint {
	display: flex;
	align-items: center;
	clear: both;
	padding: 15px 0 10px;
	font-size: 16px;
	font-weight: 350;
	p {
		margin: 0;
		font-size: 12px;
		color: #aaa;
	}
	.full-width {
		margin-right: 10px;
	}
	.print-button {
		margin-top: 0;
		border: 0;
		border-radius: 3px;
		padding: 10px 15px;
	}
}

.SeamlessPrintInstructions-TestAction {
	display: inline-block;
	width: 50%;

	padding: 20px 10px 0;
}

.SeamlessPrintInstructions-Backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;

	height: 100%;
	width: 100%;

	background-color: rgba(0, 0, 0, 0.5);
}


.print-manager-state-text {
	color: #c0392b;
	padding: 10px;
	text-align: center;
}
