// common classes
.rotate-180{
	-webkit-transform: rotate(180deg);
}
.white-text{
	color: #fff;
}
.theme-color-1-text{
	color: $theme-color-1;
}

.theme-color-2-text{
	color: $theme-color-2;
}
.theme-color-3-text{
	color: $theme-color-3;
}

.theme-bg-1{
	background: $theme-color-1;
}

.theme-bg-2{
	background: $theme-color-2;
}
.error-bg{
	background: $error-color;
	color: #fff;
}

.disabled{
	opacity:0.5;
	cursor: default;
	&.no-click {
		pointer-events: none;
	}
}

.abs-pos {
	position:absolute;
}

.fixed-pos {
	position:fixed;
}

.relative-pos {
	position:relative;
}

.full-width {
	width:100%;
}

.clearfix {
	clear:both
}

.float-left {
	float:left;
}

.float-right {
	float:right;
}

.uppercase{
	text-transform:uppercase;
}
.capitalize{
	text-transform: capitalize;
}

.cursor-pointer{
	cursor:pointer;
	touch-action: manipulation;
}

.hide{
	display:none;
}

.hide-imp{
	display: none !important;
}

.padding-10{
	padding:10px;
}
.padding-20{
	padding:20px;
}
.margin-10{
	margin: 10px;
}
.m-b-5 {
	margin-bottom:5px;
}
.m-t-5 {
	margin-top:10px;
}

.m-t-10 {
	margin-top:10px;
}

.m-b-10 {
	margin-bottom:10px;
}

.m-t-20 {
	margin-top:20px;
}
.m-b-20 {
	margin-bottom:20px;
}
.m-l-5 {
	margin-left:5px;
}
.m-r-5{
	margin-right: 5px;
}
.m-l-10 {
	margin-left:10px;
}
.m-r-10 {
	margin-right:10px;
}


.bold {
	font-weight:600;
}

.text-center{
	text-align:center;
}

.text-right{
	text-align:right;
}

.text-left{
	text-align:left;
}

.small-text{
	font-size:$small-font-size;
}

.large-text{
	font-size:$large-font-size;
}

.x-large-text{
	font-size:$x-large-font-size;
}

.med-text{
	font-size:$med-font-size;
}

.secondary-text{
	color: $secondary-text-color;
}


.underline-text{
	text-decoration:underline;
}

.div-table{
	display: table;
	width: 100%;
}

.div-table-row {
	display: table-row;
}

.div-table-cell {
	display: table-cell;
	vertical-align: middle;
	padding: 10px;
	&.top{
		vertical-align: top;
	}
	@include bp(768px){
		padding: 10px 5px;
	};
}

.div-table-cell:nth-child(1){
	text-align: left;
}

.border-bottom{
	padding-bottom: 10px;
	border-bottom: 1px solid $border-color;
}
.margin-auto{
	margin: auto;
}
.border-top{
	padding-top: 10px;
	border-top: 1px solid $border-color;
}
.border-curve{
	border-radius: $default-border-radius;
	overflow: hidden;
}
a,a:link,a:visited,a:hover{
		color:inherit;
		text-decoration:none;

		&.underline-text{
			@extend .underline-text;
		}
}

.valign-absolute {
	@extend .abs-pos;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}
.valign-absolute-offset {
	@extend .abs-pos;
	top: 40%;
	transform: translateY(-10%);
	-webkit-transform: translateY(-10%);
	-ms-transform: translateY(-10%);
}
.halign-absolute {
	@extend .abs-pos;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
}

.center-absolute{
	@extend .abs-pos;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}

.inline-block{
	display: inline-block;
}
.default-inline-container{
	& > div{
		display:inline-block;
		vertical-align : middle;
	}
}

.top-inline-container{
		& > div{
				display:inline-block;
				vertical-align : top;
		}
}

.messaging{
	font-size:$small-font-size;

	.med{
		font-size:$med-font-size;
	}
	.large{
		font-size:$large-font-size;
	}
	.error{
		color:$error-color;
	}

	&.success{
		color:$success-color;
	}
}

.UP-ERROR-TEXT{
	color:$error-color;
}
.UP-SUCCESS-TEXT{
	color: $success-color;
}


.up-button {
	font-size: 12px;
	cursor: pointer;
	touch-action: manipulation;
	display: inline-block;
	@extend .uppercase;
	&.small{
		padding: 5px 15px;
	}

	&.large{
		padding: 15px 20px;
	}

	&.default{
		border-radius: $default-border-radius;
		color: #fff;

		&.progress-status{
			animation-duration: 1s;
			animation-fill-mode: forwards;
			animation-iteration-count: infinite;
			animation-name: placeHolderShimmer;
			animation-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
			border-radius: 2px;
			background-size: 50%;
			position: relative;
		}
		&.primary{
			background-color: #39b54a;
  			border: solid 0.5px #249533;

			&.progress-status{
			    background-image: linear-gradient(to right, #39b54a 0%, #249533 50%, #39b54a 100%);
			}
		}

		&.secondary{
			color: #999;

			&:hover{
				background: #f2f2f2;
				color: $theme-color-2;
			}
		}

		&.secondary-2{
			color: $theme-color-1;
			border: 1px solid $theme-color-1;
		}

		&.secondary-3{
			color: #fff;
		}


		&.dismiss{
			background-color: #ed565b;
			border: solid 0.5px #e51c23;
			color: #fff;

			&.progress-status{
			    background-image: linear-gradient(to right, #ed565b 0%, #e51c23 50%, #ed565b 100%);
			}
		}
		&.warning {
			background-color: #f1c40f;
			border: 0.5px solid #f1c40f;
			color: white;
		}

		@extend  .default-transition;
	}

	&.full-width{
		width: 100%;
		text-align: center;
		box-sizing: border-box;
	}

	&.half-width{
		width: 48%;
		display: inline-block;
		box-sizing: border-box;
		padding: 15px;
		text-align: center;
		border-radius: 0px;
		color: #fff;
		border-radius: $default-border-radius;
		&.secondary{
			border: 0px;
			border:1px solid #ddd;
		}
		&.primary{
			border: 0px;
			border:1px solid $theme-color-3;
		}

		&:nth-child(1){
			margin-right: 2%;
		}
		&:nth-child(2){
			margin-left: 2%;
		}
	}
	&.fixed-width{
		width: 200px;
	}
	&.rider-temp {
		display: block;
		background-color: #f1c40f;
		text-align: center;
	}
}

.invisible{
	visibility: hidden;
}

.up-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #fff;
	z-index: 999;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(50, 50, 50, 0.2);
	-moz-box-shadow:		0px 1px 5px 0px rgba(50, 50, 50, 0.2);
	box-shadow:				 0px 1px 5px 0px rgba(50, 50, 50, 0.2);
	height:$header-height;
}

.up-page{
	padding-top:$header-height+50;
	padding-bottom:$header-height;
}

.spinner{
	@extend .fixed-pos;
	display: none;
	z-index: 1000;
	background: rgba(0,0,0,0.4);
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;

	&.active {
		display: block !important;
	}
}

.spinner_cont{
	width: 100px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);

	& > div {
		width: 10px;
		height: 10px;
		background-color: #fff;
		border-radius: 100%;
		display: inline-block;
		animation: sk-bouncedelay 1.7s infinite ease-in-out both;
		-webkit-animation: sk-bouncedelay 1.7s infinite ease-in-out both;
		-moz-animation: sk-bouncedelay 1.7s infinite ease-in-out both;
		-o-animation: sk-bouncedelay 1.7s infinite ease-in-out both;
		margin-right: 5px;
	}

	.spinner__item1 {
		animation-delay: -0.60s;
		-webkit-animation-delay: -0.60s;
		-o-animation-delay: -0.60s;
		-moz-animation-delay: -0.60s;
	}

	.spinner__item2 {
		animation-delay: -0.40s;
		-webkit-animation-delay: -0.40s;
		-moz-animation-delay: -0.40s;
		-o-animation-delay: -0.40s;
	}

	.spinner__item3 {
		animation-delay: -0.20s;
		-webkit-animation-delay: -0.20s;
		-moz-animation-delay: -0.20s;
		-o-animation-delay: -0.20s;
	}
}


.new-order-notification-gif{
	animation: bottom-ripple 1s ease-in-out infinite -50ms;
	background-color: #D2527F;
	border-radius: 50%;
	box-shadow: 0 0.5em 0.5em rgba(0,0,0, 0.4);
	height: 15px;
	position: relative;
	width: 15px;
	display: inline-block;
}

.default-transition{
	-webkit-transition: ease-in-out 0.5s;
	transition: ease-in-out 0.5s;
}
.viewport{
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: $max-grid-width;
}

.switch{
	display: table;
	margin: auto;
	text-transform: uppercase;
	cursor: pointer;
	&>div{
		display: table-cell;
		padding: 10px;
		vertical-align: middle;
	}


	.switch-rail{
		display: inline-block;
		width: 30px;
		position: relative;
		background: #ddd;
		border-radius: 20px;
		padding: 10px;
		&:after{
			content: "";
			width: 20px;
			height: 20px;
			background: $theme-color-3;
			border-radius: 100%;
			position: absolute;
			left: 0px;
			top:0px;
			transition: 0.5s;
		}
	}
	&.switched{
		.switch-rail{
			&:after{
				left: 30px;
			}
		}
	}
	.switched-el{
		color: $theme-color-3;
		font-weight: bold;
	}
}
.custom-time-picker{
	width: 150px;
	border: 1px solid #ddd;
	border-radius: 4px;
	margin: auto;
	&:after{
		content: "";
		display: table;
		clear: both;
	}
	&>div{
		float: left;
		width: 45px;
		height: 41px;
		&:after{
			content: ":";
			position: absolute;
			line-height: 39px;
		}
		&:last-child{
			&:after{
				content: "";
			}
		}
		input{
			width: 100%;
			box-sizing: border-box;
			border: 0px;
			line-height: 39px;
			text-align: center;
			outline: 0;
		}
	}
}


.bill {
	display: none;

	.item-taxes-container{
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 400;
	}
}
.order-detail-container-styling-class{
	position: fixed;
	right: 0;
	width: 65%;
	height: 100%;
	z-index: 999;
	background-color: #fff;
	top:0;
	overflow-y:scroll;
	box-shadow:-1px 0px 9px 0px rgba(50, 50, 50, 0.67);
	@include bp(768px){
		width: 100%;
		overflow: auto;
	};
}
@media print {
	*::-webkit-scrollbar {
		width: 0 !important;
		display: none;

	}
	.order-detail-container-styling-class{
		position: relative !important;
		box-shadow:none !important;
	}
	.no-internet-dialog-bar{
		display: none !important;
	}
    .order-summary {
        display: none;
    }
	.order-detail-container-styling-class{
		width: 100%;
	}

    .bill {
        display: block !important;
        max-width: 350px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        text-align: left;
		color: #000 !important;

        .div-table-cell, p {
            padding: 5px;
			vertical-align: top;
        }

        .align-right {
            text-align: right;
        }

        .align-center {
            text-align: center;
        }

        .align-left {
            text-align: left;
        }

        .width33 {
            width: 33.33%;
        }

        .items {
          .first {
            width: 50%;
          }

          .second {
            width: 25%;
          }

          .third {
            width: 25%;
          }
        }

        .width50 {
            width: 50%;
            max-width: 50%;
        }

		.width20 {
            width: 20%;
            max-width: 20%;
        }

		.width80 {
            width: 80%;
            max-width: 80%;
        }

        .item-footer-row {
            padding-right: 40px;
        }
      }
 }

 // UPList Component styles
.up-list-container{
	border-radius: 4px;
	border-width: 1px;
	border-color: #e2e2e2;
	border-style: solid;
	* {
		box-sizing: border-box;
	}
	.up-table-header{
		width: 100%;
		overflow: hidden;
	}
 }
.up-table-container{
	// max-height: calc(100vh - 200px);
	// overflow: overflow;
	overflow-x: hidden;
}
table.up-table {
 	width: 100%;
 	background-color: #ffffff;
 	color: #fff;
 	td, th {
 		border-width: 0px;
 		border-color: #ffffff;
 		border-style: solid;
 		padding: 0px;
		text-transform: capitalize;
		text-align: center;
		& > div{
			padding: 8px 5px;
		}
 	}
	th{
		& > div{
			font-weight: 500;
			padding: 12px 5px;
		}
		border-bottom: 1px solid #e2e2e2;
	}
 	tbody tr{
 		cursor: pointer;
 		&:hover, &.active{
 			background-color: #e2e2e2;
 			color: #fff;
 		}
		&:nth-child(even){
			background-color: #f2f2f2;
		}
 	}
 	td{
 		color: $default-text-color;
 	}
 	thead {
		background-color: #f6f7f8;
		background-image: -webkit-linear-gradient(#fff, #efefef);
		background-image: linear-gradient(#fff, #efefef);
		color: $default-text-color;
		.sort-icons{
			margin-left: 3px;
			white-space: nowrap;
			img{
				width: 12px;
			}
		}
		.sortable-col{
			color: #4693d4;
			.up, .down{
				display: none;
			}
		}
		.sortable-col[order^="asc"]{
			.up, .both{
				display: none;
			}
			.down{
				margin-left: 0;
				display: inline-block;
			}
		}
		.sortable-col[order^="desc"]{
			.down, .both{
				display: none;
			}
			.up{
				margin-left: 0;
				display: inline-block;
			}
		}
 	}
 }

 .scrollbar-style{
     &::-webkit-scrollbar{
         width:6px;
         background-color:#f2f2f2;
     }
     &::-webkit-scrollbar-thumb{
         background-color:lighten($theme-color-2, 3%);
         border-radius:10px;
     }
     &::-webkit-scrollbar-thumb:hover{
         background-color:$theme-color-2;
         border:1px solid $theme-color-2;
     }
     &::-webkit-scrollbar-thumb:active{
         background-color:$theme-color-2;
         border:1px solid $theme-color-2;
     }
     &::-webkit-scrollbar-track{
         border:1px gray #e2e2e2;
         border-radius:10px;
     }
}

.flat-scrollbar{
	// overflow-y: auto;
	// overflow-y: overlay;
     &::-webkit-scrollbar{
         width:6px;
         background-color:#f1f1f1;
		 display: none;
		 @include bp(768px){
			 display: initial;
		 }
     }
     &::-webkit-scrollbar-thumb{
         background-color:#c1c1c1;
         border-radius:5px;
     }
     &::-webkit-scrollbar-thumb:hover{
          background-color:#c1c1c1;
     }
     &::-webkit-scrollbar-thumb:active{
          background-color:#c1c1c1;
     }
     &::-webkit-scrollbar-track{
         border:1px gray #e2e2e2;
         border-radius:5px;
     }
	 &:hover{
 		&::-webkit-scrollbar{
 			display: initial;
 		}
 	}
}

.no-scrollbar{
   &::-webkit-scrollbar {
	   display: none;
   }
}

.contactless-label-container {
	.green-mark {
		display: inline-block;
		height: 7px;
		width: 7px;
		border-radius: 100%;
		background-color: #57980b;
		margin-right: 5px;
	}
	.highlight {
		font-weight: bold;
	}
}

.order-taker-user-addresses {
	width: 450px;
	margin: 35px auto;
	border: 1px solid $border-color;
	border-radius: 3px;
	.no-address-found {
		text-align: center;
		color: red;
		font-weight: bold;
		padding: 20px 0;
	}
	.address-found-header {
		border-bottom: 1px solid $border-color;
		padding: 15px;
		font-weight: bold;
	}
	.saved-address-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.address-value {
			width: 75%;
			.address-tag {
				font-weight: bold;
				margin-bottom: 5px;
			}
		}
		input {
			width: 25%
		}
	}
}
