.main-table-container {
	.main-table-white-layer {
		position: sticky;
		top: 60px;
		left: 0;
		z-index: 1;

		margin-top: -22px;

		height: 20px;

		background-color: white;
		border-bottom: 1px solid #e2e2e2;
	}

	@media screen and (max-width: 992px) {
		.main-table-white-layer {
			top: 86px;
		}
	}
}

.custom-table-body-row {
	.status-changer {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		height: 100%;

		.status-changer-btn-accept,
		.status-changer-btn-cancel {
			outline: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			text-transform: uppercase;
			font-size: 11px;
			color: #fff;
			height: 30px;
			min-width: 30px;
			border: 0;
			border-radius: 4px;

			transition: 180ms;
			box-shadow: 2px 2px 10px 1px #bfbfbf;

			&:disabled {
				opacity: 0.2;
				box-shadow: none;
			}
		}

		.status-changer-btn-accept {
			margin-right: 10px;
			background-color: #2c3e50;
		}

		.status-changer-btn-cancel {
			background-color: #c0392b;
		}
	}
}
