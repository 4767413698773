.inventory-biz-store-selector {
	display: inline-block;
	width: 50%;
	&.full-width {
		display: block;
		width: 100%;
	}
	@include bp(768px) {
		width: 100%;
	};
	.flex-container {
		display: flex;
		.store-dropdown-hq {
			flex: 1;
		}
		.biz-dropdown-hq {
			margin-right: 10px;
		}
	}
	&.biz-settings {
		.biz-dropdown-hq {
			margin-right: 0;
		}
	}
}

.inp-field-custom-label {
	position: relative;
	.custom-label {
		position: absolute;
		z-index: 2;
		font-size: 10px;
		left: 6px;
		top: -5px;
		padding: 0 5px;
		background: white;
	}
}

.inventory-table-container{
	border:1px solid #ccc;
	@include bp(768px){
		border: 0px;
	};
	.inventory-table-container-header{
		font-size: $large-font-size;
		color: $dark-text-color;
		display: inline-block;

		&.col-1{
			width: 50%;
		}

		&.col-2{
			width: 50%
		}

		@include bp(768px){
			&.col-1{
				width: 100%;
			}

			&.col-2{
				display: none;
			}
		};
	}
	.inventory-table-container-body{
		.InventorySwitch-comp{
			display: inline-block;
			text-align: left;
			box-sizing:border-box;
		}

		.inventory-item-row-item-name {
			width: 50%;
			display: inline-block;
			vertical-align: top;

			overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
			box-sizing: border-box;
			border-right: 1px solid rgb(221, 221, 221);

			.stock-text{
				float: right;
				margin-right: 10px;
			}

			.inventory-item-title{
				padding: 10px;
			}
			.inventory-item-option-button {
				padding: 2px 10px;
				border-radius: 3px;
				border: 1px solid #10c61c;
				cursor: pointer;
				transition: 180ms;
				font-size: 10px;
				&:hover {
					color: white;
					background: #10c61c;
				}
			}
		}
		.stocks-control-container {
			width: 50%;
			display: inline-block;
			vertical-align: top;
			text-align: left;
		}

		@include bp(768px){
			.inventory-item-row-item-name {
				flex: 1;
			}
			.stocks-control-container {
				width: 100px;
				text-align: center;
			}
		};
	}
}

.cls-inventory-search{
	cursor: pointer;
	background-image: url('/images/close.png');
	position: absolute;
	width: 20px;
    height: 20px;
    top: 8px;
    right: 5px;
    position: absolute;
    background-color: #ccc;
    background-size: 10px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    border: 1px solid #ccc;
	display: none;
}
.inventory-table-container-header.col-2{
	text-align: left;
	&>div{
		&:first-child{
			text-align: left;
			border-left: 0px !important;
		}
	}
}
.inventory-table-container-header.col-1{
	height: 50px;
	white-space: nowrap;

	border-right:1px solid rgb(221, 221, 221);
	@include bp(768px){
		border-right: 0px;
	};
	box-sizing: border-box;

	&>div{
		width: 31%;
		transition: 0.5s;
	}

	&.searching{
		overflow: hidden;
		.cls-inventory-search{
			display: block;
		}
		.inventory-item-search{
			width: 98%
		}

	}
}
.category-dropdown-inventory{
	font-size: 12px;
}
.inventory-item-search{
	input{
		outline: 0px;
		padding: 10px 10px;
	    width: 100%;
		box-sizing: border-box;
		background-color: #fff;
	    border-color: #d9d9d9 #ccc #b3b3b3;
	    border-radius: 4px;
	    border: 1px solid #ccc;
	    font-size: 12px;
		transition: 0.2s;

		&:focus{
			background: #fff;
		}
	}
}
