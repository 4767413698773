.call-animation-container{
	background-color: $dark-bg-color;
	background-image: url('/images/phone.png');
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 120px;
	height: 120px;
	position: relative;
	margin: 0 auto;
	border-radius: 100%;
	animation: play 1.5s ease infinite;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	background-size: 80px;
    background-position: left 25px center;
    background-repeat: no-repeat;


}
