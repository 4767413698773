.crossPlatformEnhancementContainer {
  .play-btn-container {
		overflow: auto;
		padding: 0;
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
		margin-top: 400px;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  h1{
      color:black;
      margin-top: 0px;
      padding: 20px 30px;
      text-align: center;
	}
  p, .subtext {
    color:black;
    margin-top: 0px;
    padding: 5px 0;
    text-align: left;
		font-size:14px;
		margin-bottom: 10px;
		span {
			font-weight: bold;
		}
  }
  img{
    max-width:100%;
  }
  .card-body {
    width: 80%;
    max-width:450px;
    margin: 0 auto;
    background-color:white;
    margin-top: 0px;
    height: 100%;
    border-radius: 5px;
		overflow: auto;
		padding: 0 30px 30px 30px;
  }

  .btn {
    border: none;
    background-color: inherit;
    font-size: 14px;
    display: inline-block;
  }

  .btn-options{
    margin: 10px 2px 25px 25px;
    float: left;

  }

  .btn-primary{
    color: dodgerblue;
	}

  .btn-secondary{
    color: gray;
  }

  .btn-close{
    margin: 10px 25px 25px 25px;
    float: right;
    color: lightcoral;
  }

	.setup-btn {
		color: white;
		background-color: dodgerblue;
		border: 1px solid dodgerblue;
		border-radius: 3px;
		padding: 5px 10px;
		margin: 0 10px;
	}

	.close-btn {
		color: white;
		background-color: lightcoral;
		border: 1px solid lightcoral;
		border-radius: 3px;
		padding: 5px 10px;
		margin: 0 10px;
	}

	.setup-btn-container {
		text-align: center;
		margin-top: 15px;
	}
}
