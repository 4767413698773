$default-font: 'PN Regular';
//$font-url :'../fonts/ProximaNova-Regular.otf';

$default-font: sans-serif;
//$font-url :'../fonts/ProximaNova-Regular.otf';


//@include font-face($default-font, url($font-url));



$theme-color-1:#38a2fb;
$theme-color-2:#34495e;
$theme-color-3:#88c057;
$theme-color-4:#ec565b;

$dark-bg-color:#1c2837;
$bg-color: rgba(245, 248, 250, 1);
$bg-color-2: #172029;

$error-color:rgba(218, 79, 55, 1);
$success-color:$theme-color-3;

$default-text-color:#354052;
$dark-text-color: #323232;
$secondary-text-color:#7f8fa4;

$border-color:rgba(230, 234, 238, 1);
$secondary-background-color:#f4f4f4;


$default-font-size: 14px;
$small-font-size: 13px;
$med-font-size: 14px;
$large-font-size: 15px;
$x-large-font-size: 30px;


$header-height:60px;

$max-grid-width:1024px;
$max-s-grid-width:768px;


$large-modal-max-width: 600px;
$default-modal-max-width: 400px;


$default-border-radius:2px;

$overlay-opacity: 0.7;


$site-banner : true;
$order-mode: true;
$item-filter: true;

$logged-in-user-background: #37b24d;
