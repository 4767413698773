.sidebar{
    box-shadow: 1px 0px 5px 0px #16242d;

    .version-no{
        position: fixed;
        bottom: 5px;
        text-transform: uppercase;
        text-align: center;
        font-size: 8px;
        left: 0px;

		color: #03a9f4;
		&:hover {
			text-decoration: underline;
		}
    }
    .counter-in-sidebar{
        background: $dark-bg-color;
        color: #fff;
        padding: 5px 7px;
        font-size: 10px;
        margin-right: 5px;
        border-radius: 2px;
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 10px;
    }
    padding-top: $header-height;

    position: fixed;
    left: 0;
    top:0;
    vertical-align: top;
    width: 160px;
    height: 100%;
    background: $bg-color-2;
    color: #fff;
    z-index: 999;

    background-image:url('/images/urbanpiper-logo.png'), url('/images/sat.png');
    background-position: bottom 20px center, top 20px center;
    background-repeat: no-repeat;
    background-size: 60%, 80%;
    box-sizing: border-box;
    &:-webkit-scrollbar {
        display: none;
    }
    .burger-open{
        display: none;
    }


    &>div{
        width: 160px;
        display: inline-block;
    }

    .nav-link-header-nested-container {
        margin-bottom: 15px;

        // Bad code here
        &:nth-child(1) {
            margin-top: 30px;
            @include bp(768px) {
                margin-top: 10px;
            };
        }
    }
    .nav-link-header{

        &>a{
            text-align: left;
            font-size: 15px;

            @include bp(768px) {
                font-size: 13px;
            }

            padding: 10px;
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            transition: 1s;
            cursor: pointer;

            span{
                transition: 0.5s;
                display: inline-block;
            }
            position: relative;
            &:before{
                display: block;
                position: absolute;
                left: 0;
                top:0;
                width: 3px;
                height: 0px;
                background-color: #88c057;
                content: "";
                display: inline-block;
                transition: height 0.2s ease-out;
            }

            &.active {
                &::before{
                    height: 36px;
                }
            }
        }
    }

    .nav-link {
        text-align: left;
        padding-left: 20px;

        .linkComp {
            padding: 10px;
            font-size: 12px;
            box-sizing: border-box;
            width: 100%;
            position: relative;
            display: inline-block;

            &.active {
                color: #2196F3;
            }
        }
    }

    @include bp(768px) {
        left: -160px;
        transition: 180ms;
        will-change: transform;

        &.active {
            transform: translateX(100%);
        }

    }
}


.burger-overlay {
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 999;
    pointer-events: none;
    transition: 180ms;

	&.active {
		opacity: 0.7;
        pointer-events: all;
	}
}
