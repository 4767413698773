@mixin font-face($name, $font-files, $eot: false, $weight: false, $style: false) {
  $iefont: unquote("#{$eot}?#iefix");
  @font-face {
    font-family: quote($name);
    @if $eot {
      src: font-url($eot);
      $font-files: font-url($iefont) unquote("format('opentype')"), $font-files;
    }
    src: $font-files;
    @if $weight {
      font-weight: $weight;
    }
    @if $style {
      font-style: $style;
    }
  }
}

@mixin con-show-hide($bool){
  @if $bool{
    display: block;
  }@else{
    display: none;
  }
}

@mixin bp($point){
	@media screen and (max-width:$point) {
		@content;
	}
}
