.custom-table-container {
	box-sizing: border-box;

	background-color: #ffffff;
	color: $default-text-color;

	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}

	border: 1px solid #e2e2e2;

	.custom-table {
		width: 100%;
	}

	.custom-table-header-row {
		display: flex;
		justify-content: center;
		align-items: center;

		position: sticky;
		top: 80px;
		z-index: 2;

		width: 100%;
		border-bottom: 1px solid #e2e2e2;
		box-shadow: 0 1px 5px 0 hsla(0, 0%, 0%, 0.25);

		background-color: #f6f7f8;
		background-image: -webkit-linear-gradient(#fff, #efefef);
		background-image: linear-gradient(#fff, #efefef);
	}

	.custom-table-header-column,
	.custom-table-body-column {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		position: relative;

		border-width: 0px;
		border-color: #ffffff;
		border-style: solid;
		margin: 0;
		padding: 0 10px;

		text-transform: capitalize;
		&.meta-info {
			font-weight: bold;
		}
	}

	.custom-table-header-column {
		height: 100%;
		font-weight: 500;

		.sortable-col {
			cursor: pointer;
		}

		// & > div {
		// 	padding: 12px 5px;
		// }

		color: $default-text-color;
		.sort-icons {
			margin-left: 3px;
			white-space: nowrap;
			img {
				width: 12px;
			}
		}
		.sortable-col {
			color: #4693d4;
		}
	}

	.custom-table-header-row-first-cell,
	.custom-table-body-row-first-cell {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.custom-table-header-row-first-cell {
		left: 35px;
	}

	.custom-table-body-row {

		&.highlight-row {
			&:after {
				content: '';
				background: #162029;
				position: absolute;
				left: 0px;
				height: 100%;
    			width: 3px;
			}
		}

		display: flex;
		align-items: center;
		flex-wrap: wrap;

		position: relative;
		overflow-x: hidden;

		width: 100%;

		cursor: pointer;

		&:nth-child(even) {
			background-color: #f2f2f2;
		}

		&:hover,
		&.active {
			background-color: #e2e2e2;
		}

		.hover-element-container {
			position: absolute;
			top: 50%;
			right: 0;
			z-index: 1;

			height: 100%;

			background-color: inherit;

			transform: translate(100%, -50%);
			transition: transform 210ms ease-out;
			padding-right: 10px;
			cursor: default;
		}

		&:hover > .hover-element-container {
			transform: translate(0, -50%);
		}
	}

	.custom-table-body-row-first-cell {
		left: 25px;

		// width: 100%;
	}

	// @media screen and (max-width: 992px) {
	// 	.custom-table-header-row {
	// 		top: 106px;
	// 	}
	//
	// 	.custom-table-header-column {
	// 		display: flex;
	// 		justify-content: flex-start;
	// 		align-items: center;
	// 	}
	//
	// 	.custom-table-header-row-first-cell {
	// 		left: 15px;
	// 	}
	//
	// 	.custom-table-body-row {
	// 		&:hover > .hover-element-container {
	// 			// transform: translate(0, -50%);
	// 			transition-delay: 0.4s;
	// 		}
	// 	}
	//
	// 	.custom-table-body-column-container {
	// 		display: flex;
	// 		justify-content: flex-start;
	// 		align-items: center;
	//
	// 		position: relative;
	//
	// 		height: 50px;
	// 		padding: 0 10px;
	// 	}
	//
	// 	.custom-table-body-column {
	// 		padding: 0;
	// 	}
	//
	// 	.custom-table-mobile-sub-row {
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	//
	// 		width: 100%;
	// 		padding: 10px;
	// 		border-bottom: 1px solid #e2e2e2;
	//
	// 		font-size: 12px;
	//
	// 		&:first-child {
	// 			margin-top: 5px;
	// 		}
	//
	// 		&:last-child {
	// 			border-bottom: 0;
	// 		}
	//
	// 		& > * {
	// 			display: inline-block;
	//
	// 			padding: 0 7px 0 0;
	// 		}
	//
	// 		& > b {
	// 			width: 37.5%;
	// 			color: #727272;
	// 		}
	//
	// 		& > span {
	// 			width: 62.5%;
	// 		}
	// 	}
	// }

	@media screen and (max-width: 768px) {
		.custom-table-header-row-first-cell {
			left: 27px;
		}

		.custom-table-header-column {
			justify-content: center;
		}

		.custom-table-body-column-container {
			justify-content: center;
		}
	}
}
